import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { navigationPaths, routerPaths } from "../../constants/paths"
import api from "../../utilities/api"
import { isFMCUser, isFMC } from "../../helpers/userRoleHelpers"
import { Redirect } from "react-router"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import FleetList from "../../components/dashboard/FleetList"
import Stats from "../../components/fleets/Stats"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import { Button, Container, Header } from "semantic-ui-react"
import { withTranslation } from "react-i18next"

class FMCDashboard extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    fleets: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    const locationStateData = this.props.location.state || {}

    this.state = {
      alertMessage: locationStateData.alertMessage || "",
      alertType: locationStateData.alertType || "default",
      filterData: this.initialFilterData()
    }
  }

  componentDidMount() {
    const { filterType, filterValue } = this.state.filterData
    const { dispatch, currentUser, fleets } = this.props
    if (currentUser) {
      dispatch({
        type: "FLEETS_LOAD_SAGA",
        payload: {
          filter: {
            filterType: filterType,
            filterValue: filterValue
          },
          page: 1
        }
      })
    }
  }

  initialFilterData() {
    let filterData = {
      filterType: "all",
      filterValue: ""
    }

    const queryString = window.location.search

    if (queryString !== "") {
      filterData["filterType"] = queryString.split("=")[0].replace("?", "")
      filterData["filterValue"] = queryString.split("=")[1].replace("%20", " ")
    }

    return filterData
  }

  onFilterChange(field, value) {
    let updatedFilterData = { ...this.state.filterData }
    updatedFilterData[field] = value

    // NOTE: Resets text field on type select change.
    if (field === "filterType") {
      updatedFilterData["filterValue"] = ""
    }

    this.setState({ filterData: updatedFilterData })
  }

  onFilterSubmit() {
    const { filterType, filterValue } = this.state.filterData

    this.props.dispatch({
      type: "FLEETS_LOAD_SAGA",
      payload: {
        filter: {
          filterType: filterType,
          filterValue: filterValue
        },
        pageLoad: "false"
      }
    })

    this.updateQueryParams()
  }

  shouldRenderContent() {
    const { isLoading, fleetsLoading, isLoadingError, fleets, currentUser } = this.props

    return (
      !isLoading &&
      !fleetsLoading &&
      !isLoadingError &&
      fleets &&
      currentUser &&
      isFMCUser(currentUser)
    )
  }

  updateQueryParams() {
    const { filterType, filterValue } = this.state.filterData

    let updatedUrl = "/"

    if (filterType !== "all") {
      updatedUrl = `/?${filterType}=${filterValue}`
    }

    window.history.replaceState({}, "", updatedUrl)
  }

  renderContent() {
    const { fleets, currentUser, t, dispatch, fleetsLoading } = this.props
    const { authentication_token } = currentUser

    if (currentUser && !isFMCUser(currentUser)) {
      // TODO: extract this work to the Router itself.
      return <Redirect to={routerPaths.dashboard} />
    } else {
      return (
        <div>
          <span />
          <Header size="small" className={"dash-hd"}>
            {t("fleetsLabel")}
          </Header>
          <Stats fleets={fleets} currentUser={currentUser} dispatch={dispatch} />
          <Container textAlign="right" fluid>
            {isFMC(currentUser) ? (
              <Button
                icon="plus"
                content={t("addNewFleetLabel")}
                onClick={() => this.props.history.push(navigationPaths.fleetNew())}
                primary
              ></Button>
            ) : (
              ""
            )}
            {authentication_token && (
              <Button
                secondary
                icon="download"
                disabled={fleets && fleets.length === 0}
                content={t("downloadLabel")}
                onClick={() =>
                  window.open(
                    api.pathV6(
                      `/fleets.csv?auth_token=${authentication_token}&lancode=${currentUser.language.toUpperCase()}`
                    )
                  )
                }
              ></Button>
            )}
          </Container>
          <FleetList
            fleets={fleets}
            language={currentUser.language}
            dispatch={dispatch}
            fleetsLoading={fleetsLoading}
          />
        </div>
      )
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props

    const { alertMessage, alertType } = this.state

    return (
      <ApplicationLayout>
        <Alert message={alertMessage} type={alertType} visible={alertMessage !== ""} />

        {/* <LoadingThrobber visible={!this.shouldRenderContent()} /> */}

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class Dashboard

const mapStateToProps = (state) => ({
  fleets: state.fleets.fleets,
  fleetsLoading: state.fleets.fleetsLoading,
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  currentUser: state.application.userSessionData || state.users.currentUser
})

export default connect(mapStateToProps)(withTranslation("fmcDashboard")(FMCDashboard))
