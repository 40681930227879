import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { FILTER_TYPE_ALL } from "../../constants/application"

export function* createFleet(action) {
  try {
    yield put({
      type: "FLEET_CREATE_REQUESTED"
    })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const formData = action.payload.formData
    const { paymentType, name, otherId, region, rep, shareWexNumber, address } = formData

    const request = {
      url: "/fleets",
      body: {
        fleet: {
          name,
          region,
          rep,
          fleet_payment_type: paymentType,
          other_id: otherId,
          share_wex_number: shareWexNumber
        }
      }
    }
    if (Object.keys(address).length > 0) {
      if (
        address.address != null ||
        address.zip != null ||
        address.city != null ||
        address.state != null
      )
        request["body"]["fleet"]["address_attributes"] = address
    }

    const response = yield call(api.utility.post, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "FLEET_CREATE_SUCCEEDED",
      payload: {
        fleet: response.body
      }
    })

    yield put({
      type: "SET_FLEET_SESSION_DATA",
      payload: response.body
    })

    yield put({
      type: "UPDATE_FLEET_COUNT_FOR_CURRENT_USER",
      payload: response.body
    })

    const afterRequestData = {
      alertMessage: action.payload.t("fleetCreatedLabel"),
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "FLEET_CREATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* createFleet

export function* deleteFleet(action) {
  try {
    yield put({ type: "FLEET_DELETE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const fleetId = action.payload.fleetId

    const request = {
      url: `/fleets/${fleetId}`,
      body: {}
    }

    const response = yield call(api.utility.del, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "FLEET_DELETE_SUCCEEDED",
      payload: {
        fleet: response.body
      }
    })

    const afterRequestData = {
      alertMessage: action.payload.t("fleetDeletedLabel"),
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "FLEET_DELETE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* deleteFleet

export function* loadFleet(action) {
  try {
    const defaultOptions = {
      includePaymentTypes: false
    }

    const payloadOptions = action.payload.options || {}

    const options = {
      ...defaultOptions,
      ...payloadOptions
    }

    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //     LOAD FLEET DATA    //
    ////////////////////////////
    yield put({ type: "FLEET_LOAD_REQUESTED" })

    const fleetId = action.payload.fleetId

    const fleetRequest = {
      url: `/fleets/${fleetId || "me"}`,
      body: {}
    }

    const fleetResponse = yield call(api.utility.get, api.path(fleetRequest.url), {
      body: fleetRequest.body
    })
    if (fleetResponse.body.account)
      sessionHelpers.setApplicationCookie("current_account_id", fleetResponse.body.account.id)
    sessionHelpers.setApplicationCookie("current_fleet_id", fleetResponse.body.id)

    yield put({
      type: "SET_FLEET_SESSION_DATA",
      payload: fleetResponse.body
    })

    apiErrorHelpers.catchErrors(fleetResponse.body)

    yield put({
      type: "FLEET_LOAD_SUCCEEDED",
      payload: {
        fleet: fleetResponse.body
      }
    })
    yield put({
      type: "SET_FLEET_SESSION_DATA",
      payload: fleetResponse.body
    })

    if (options.includePaymentTypes) {
      ///////////////////////////////////
      // LOAD FLEET PAYMENT TYPES DATA //
      ///////////////////////////////////
      yield put({ type: "FLEET_PAYMENT_TYPES_LOAD_REQUESTED" })

      const fleetPaymentTypesRequest = {
        url: "/fleet_payment_types",
        body: {}
      }

      const fleetPaymentTypesResponse = yield call(
        api.utility.get,
        api.path(fleetPaymentTypesRequest.url),
        { body: fleetPaymentTypesRequest.body }
      )

      apiErrorHelpers.catchErrors(fleetPaymentTypesResponse.body)

      yield put({
        type: "FLEET_PAYMENT_TYPES_LOAD_SUCCEEDED",
        payload: {
          paymentTypes: fleetPaymentTypesResponse.body
        }
      })
      ///////////////////////////////////
    }

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "FLEET_LOAD_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* loadFleets(action) {
  try {
    const defaultOptions = {
      includePaymentTypes: false
    }

    const payloadOptions = action.payload.options || {}

    const options = {
      ...defaultOptions,
      ...payloadOptions
    }
    const page = action.payload.page || {}
    const query = action.payload.query || ""
    const pageLoad = action.payload.pageLoad || "true"

    if (parseInt(page) == 1 && pageLoad == "true") {
      yield put({ type: "SET_LOADING" })
    }
    // There's a bug where if `SET_LOADING` is called from multiple sagas, `UNSET_LOADING` will be
    // called and errantly indicate that all loading is finished. Solving this by defining another
    // loading attr here to fix FMCDashboard, but this is likely happening in other places too.
    if (parseInt(page) == 1 && pageLoad == "true") {
      yield put({ type: "SET_FLEETS_LOADING" })
    }

    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //    LOAD FLEETS DATA    //
    ////////////////////////////
    yield put({ type: "FLEETS_LOAD_REQUESTED" })

    const filter = action.payload.filter || {}
    const { filterType, filterValue } = filter

    let queryString = ""

    if (filterType && filterType !== FILTER_TYPE_ALL && filterValue) {
      queryString = `?${filterType}=${filterValue.replace(" ", "%20")}`
    }

    const fleetsRequest = {
      url: `/fleets${queryString}`,
      body: { page: parseInt(page), query: query }
    }

    const fleetsResponse = yield call(api.utility.get, api.pathV6(fleetsRequest.url), {
      body: fleetsRequest.body
    })

    apiErrorHelpers.catchErrors(fleetsResponse.body)

    yield put({
      type: "FLEETS_LOAD_SUCCEEDED",
      payload: {
        fleets: fleetsResponse.body,
        page: parseInt(page),
        query: query
      }
    })
    ////////////////////////////

    if (options.includePaymentTypes) {
      ///////////////////////////////////
      // LOAD FLEET PAYMENT TYPES DATA //
      ///////////////////////////////////
      yield put({ type: "FLEET_PAYMENT_TYPES_LOAD_REQUESTED" })

      const fleetPaymentTypesRequest = {
        url: "/fleet_payment_types",
        body: {}
      }

      const fleetPaymentTypesResponse = yield call(
        api.utility.get,
        api.path(fleetPaymentTypesRequest.url),
        { body: fleetPaymentTypesRequest.body }
      )

      apiErrorHelpers.catchErrors(fleetPaymentTypesResponse.body)

      yield put({
        type: "FLEET_PAYMENT_TYPES_LOAD_SUCCEEDED",
        payload: {
          paymentTypes: fleetPaymentTypesResponse.body
        }
      })
      ///////////////////////////////////
    }
    if (action.callback) {
      action.callback()
    }

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_FLEETS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_FLEETS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadFleets

export function* allLoadFleets(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ////////////////////////////
    //    LOAD FLEETS DATA    //
    ////////////////////////////
    yield put({ type: "FLEETS_LOAD_REQUESTED" })

    const fleetsRequest = {
      url: `/fleets/load_all_fleets`,
      body: {}
    }

    const fleetsResponse = yield call(api.utility.get, api.path(fleetsRequest.url), {
      body: fleetsRequest.body
    })

    apiErrorHelpers.catchErrors(fleetsResponse.body)

    yield put({
      type: "ALL_FLEETS_LOAD_SUCCEEDED",
      payload: {
        all_fleets: fleetsResponse.body
      }
    })
    ////////////////////////////
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_FLEETS_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_FLEETS_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* allLoadFleets

export function* loadPaymentTypes(action) {
  try {
    yield put({ type: "SET_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    ///////////////////////////////////
    // LOAD FLEET PAYMENT TYPES DATA //
    ///////////////////////////////////
    yield put({ type: "FLEET_PAYMENT_TYPES_LOAD_REQUESTED" })

    const request = {
      url: "/fleet_payment_types",
      body: {}
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "FLEET_PAYMENT_TYPES_LOAD_SUCCEEDED",
      payload: {
        paymentTypes: response.body
      }
    })
    ////////////////////////////

    yield put({ type: "UNSET_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadPaymentTypes

// TODO (MPH): Specs. I can do these in the future, or just model off of another fleet spec. The way saga specs work, they check that things complete as expected, in order. So you just need to make sure you dont skip a spec block for any steps.
export function* impersonateFleet(action) {
  try {
    yield put({ type: "FLEET_IMPERSONATION_SAGA_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const fleetId = action.payload.fleetId

    // TODO (MPH): uncomment
    // TODO (MPH): set url to correct endpoint
    const request = {
      url: `/fleet_users/impersonate_fleet`,
      body: { fleet_id: fleetId }
    }

    // TODO (MPH): uncomment
    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    // TODO (MPH): uncomment
    apiErrorHelpers.catchErrors(response.body)

    // Reload the current user (FMC)
    // yield put({ type: "CURRENT_USER_LOAD_SAGA", payload: { skipLoadingCalls: true } })

    // NOTE: Sets application cookies for FleetMD, not this app.
    yield put({
      type: "FLEET_IMPERSONATION_SUCCEEDED"
    })

    yield put({
      type: "UPDATE_USER_SESSION_DATA",
      payload: {
        fleet_id: fleetId
      }
    })

    const afterRequestData = {}

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "FLEET_IMPERSONATION_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* impersonateFleet

export function* updateFleet(action) {
  try {
    yield put({ type: "FLEET_UPDATE_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const formData = action.payload.formData || {}
    const { id, databaseName } = action.payload
    const { paymentType, name, otherId, region, rep, address } = formData
    let params = {}

    if (Object.keys(formData).length > 0) {
      params = {
        fleet_payment_type: paymentType,
        name: name,
        other_id: otherId,
        region: region,
        rep: rep
      }
      if (Object.keys(address).length > 0) {
        if (
          address.address != null ||
          address.zip != null ||
          address.city != null ||
          address.state != null
        )
          params["address_attributes"] = address
      }
    } else {
      params = { geotab_database_name: databaseName }
    }

    const request = {
      url: `/fleets/${id}`,
      body: { fleet: params }
    }

    const response = yield call(api.utility.put, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "FLEET_UPDATE_SUCCEEDED",
      payload: {
        fleet: response.body
      }
    })

    yield put({
      type: "SET_FLEET_SESSION_DATA",
      payload: response.body
    })

    const afterRequestData = {
      alertMessage: action.payload.t("fleetUpdatedLabel"),
      alertType: "success"
    }

    yield call(action.callback, "success", afterRequestData)
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "FLEET_UPDATE_FAILED" })

    const afterRequestData = {
      alertMessage: apiErrorHelpers.toString(errors),
      alertType: "error"
    }

    yield call(action.callback, "failure", afterRequestData)

    apiErrorHelpers.consoleLog(errors)
  }
} // function* updateFleet

export function* loadActiveOrders(action) {
  try {
    yield put({ type: "FLEET_ACTIVE_ORDERS_REQUESTED" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })

    const request = {
      url: `/orders/fleet_orders`
    }

    const response = yield call(api.utility.get, api.path(request.url))

    yield put({
      type: "FLEET_ACTIVE_ORDERS_SUCCEEDED",
      payload: {
        fleetOrders: response.body
      }
    })

    yield call(action.callback, "success")
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "FLEET_ACTIVE_ORDERS_FAILED" })

    apiErrorHelpers.consoleLog(errors)
  }
}

export function* getSummaryData(action) {
  try {
    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie("authentication_token")
    })
    const { data } = action.payload
    const request = {
      url: "/fleets/summary_data",
      body: data
    }

    const response = yield call(api.utility.get, api.pathV6(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    if (action.callback) {
      yield call(action.callback, "success", response.body)
    }
  } catch (errors) {
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({
      type: "GET_CARADVISE_RECOMMENDED_SERVICES_FAILED",
      payload: {
        recommendedServices: []
      }
    })

    if (action.callback) yield call(action.callback, "failure")

    apiErrorHelpers.consoleLog(errors)
  }
}

export default function* fleetSaga() {
  yield takeEvery("FLEET_CREATE_SAGA", createFleet)
  yield takeEvery("FLEET_DELETE_SAGA", deleteFleet)
  yield takeEvery("FLEET_IMPERSONATION_SAGA", impersonateFleet)
  yield takeEvery("FLEET_UPDATE_SAGA", updateFleet)
  yield takeEvery("FLEET_LOAD_SAGA", loadFleet)
  yield takeEvery("FLEET_PAYMENT_TYPES_LOAD_SAGA", loadPaymentTypes)
  yield takeEvery("FLEETS_LOAD_SAGA", loadFleets)
  yield takeEvery("ALL_FLEETS_LOAD_SAGA", allLoadFleets)
  yield takeEvery("FLEET_ACTIVE_ORDERS_SAGA", loadActiveOrders)
  yield takeEvery("GET_SUMMARY_DATA", getSummaryData)
}
